import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";

const ImpressumPage = () => {
    return (
        <Layout>
            <SEO title="Impressum" lang="de"/>
            <section className="titleScreen">
                <div className="container content bgGray--80 rounded__biggerScreens impressum">
                    <h1 className="title title--site serif italic">Impressum</h1>
                    <h2 className="title--section serif italic">Angaben gemäß § 5 TMG:</h2>
                    <p>Walhalla zum Seidlwirt GbR<br/>
                        Berk Altan, Julian Twarowski<br/>
                        Heidebrinker Straße 1<br />
                        13357 Berlin</p>
                    <h2 className="title--section serif italic">Vertreten durch:</h2>
                    <p>Berk Altan<br/>
                        Julian Twarowski</p>
                    <h2 className="title--section serif italic">Kontakt:</h2>
                    <table>
                        <tbody>
                        <tr>
                            <td>E-Mail:</td>
                            <td>julian@wzums.com</td>
                        </tr>
                        </tbody>
                    </table>
                    <h1 className="title serif italic">Haftungsausschluss (Disclaimer)</h1>
                    <h2 className="title--section serif italic">Haftung für Inhalte</h2>
                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                        Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                    <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                        der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    <h2 className="title--section serif italic">Haftung für Links</h2>
                    <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                        haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                        Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                        erkennbar.</p>
                    <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                        werden wir derartige Links umgehend entfernen.</p>
                    <h2 className="title--section serif italic">Urheberrecht</h2>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                        nicht kommerziellen Gebrauch gestattet.</p>
                    <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                        Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                        trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                        entfernen.</p>
                    <h1 className="title serif italic">Datenschutzerklärung</h1>
                    <h2 className="title--section serif italic">Datenschutz</h2>
                    <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
                        Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                        Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                    <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit
                        auf
                        unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen)
                        erhoben
                        werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                        ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
                    <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                        E-Mail) Sicherheitslücken aufweisen
                        kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                        möglich.</p>
                    <h2 className="title--section serif italic">Datenschutzerklärung für die Nutzung von Google
                        Analytics</h2>
                    <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die
                        Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.</p>
                    <p>Google Analytics verwendet
                        so genannte &#8222;Cookies&#8220;. Das sind Textdateien, die auf Ihrem Computer gespeichert
                        werden und die eine Analyse
                        der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
                        über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA
                        übertragen und dort gespeichert.</p>
                    <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
                        Datenschutzerklärung von Google: <a
                            href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245 </a>
                    </p>
                    <h3 className="title--par">Browser Plugin</h3>
                    <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                        Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                        diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden
                        nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf
                        Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                        Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
                        verfügbare Browser-Plugin herunterladen und installieren: <a
                            href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>
                    </p>
                    <h3 className="title--par">IP-Anonymisierung</h3>
                    <p>Wir nutzen die Funktion &#8222;Aktivierung der IP-Anonymisierung&#8220; auf
                        dieser Webseite. Dadurch wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten
                        der
                        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
                        Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
                        von
                        Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird
                        Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
                        die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
                        Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
                        Rahmen von Google
                        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                        zusammengeführt.</p>
                </div>
            </section>
        </Layout>
    )
}

export default ImpressumPage